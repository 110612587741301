@use './utils/custom' as ftx;


@mixin custom-style($theme) {
  .mat-mdc-form-field {
    --mat-mdc-form-field-floating-label-scale: 0.85;
  }

  .mat-mdc-dialog-surface {
    overflow: hidden;
  }

  .mdc-text-field {
    background-color: ftx.get-theme-color($theme, surface-container-high);
  }

  .surface-cl {
    background-color: ftx.get-theme-color($theme, surface-container-low);
  }

  .surface-ch {
    background-color: ftx.get-theme-color($theme, surface-container-high);
  }

  .surface {
    background-color: ftx.get-theme-color($theme, surface-container-high);
  }

  .vertical-button {
    --mat-text-button-icon-spacing: 0;

    .mat-icon {
      color: ftx.get-theme-color($theme, on-surface);
    }

    &.active {
      background-color: ftx.get-theme-color($theme, secondary-container);
    }
  }

  .navigation-item {
    font-size: 12px;
  }


  .messaging-container {
    background-color: ftx.get-theme-color($theme, surface-container-low);
  }

  .active-room {
    background-color: ftx.get-theme-color($theme, surface-container-highest);
  }

  .messaging-content {
    background-color: ftx.get-theme-color($theme, surface-bright);
    color: ftx.get-theme-color($theme, on-surface);
    border-radius: 1rem;
  }

  .messaging-sidenav .contacts .contact:hover {
    background-color: ftx.get-theme-color($theme, surface-container-lowest);
  }



  .fx-card-toolbar, .mat-mdc-header-row, .fc-header-toolbar, .mat-mdc-paginator {
    background-color: ftx.get-theme-color($theme, on-secondary);
    color: ftx.get-theme-color($theme, secondary);
  }

  .mat-mdc-header-row, .fc-header-toolbar {
    border-bottom: 2px solid ftx.get-theme-color($theme, primary);
  }

  .fx-card-toolbar {
    min-height: 46px !important;
    border-bottom: 1px solid ftx.get-theme-color($theme, outline-variant);

    .mat-mdc-menu-trigger {
      background-color: ftx.get-theme-color($theme, surface-container-low);
      color: ftx.get-theme-color($theme, on-surface);
    }

  }

  .ftx-dialog-toolbar {
    background-color: ftx.get-theme-color($theme, secondary);
    color: ftx.get-theme-color($theme, on-secondary);

    .mat-icon {
      color: ftx.get-theme-color($theme, on-secondary);
    }
  }

  .fx-dialog-content, .ftx-list-item-active {
    background-color: ftx.get-theme-color($theme, surface-container-high);
    color: ftx.get-theme-color($theme, on-surface);
  }

  .mat-mdc-dialog-actions {
    @extend .fx-dialog-content;
    border-top: 1px solid ftx.get-theme-color($theme, outline-variant);
  }

  .mat-expansion-panel, .mat-mdc-dialog-content {
    background-color: ftx.get-theme-color($theme, surface-container-low);
    box-shadow: none !important;
  }

  #login, body {
    background-color: ftx.get-theme-color($theme, surface-container);
  }

  .login-card {
    background-color: ftx.get-theme-color($theme, surface-container-low);
  }

  .report-dialog-preview {
    background-color: ftx.get-theme-color($theme, surface-container-low);
  }

  .fc .calendar-resource-button {
    color: ftx.get-theme-color($theme, on-primary);
  }
  .fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: ftx.get-theme-color($theme, secondary-container);
    color: ftx.get-theme-color($theme, on-secondary-container);
  }
  .fc .fc-button-primary:not(:disabled):hover {
    background-color: ftx.get-theme-color($theme, primary-container);
    color: ftx.get-theme-color($theme, on-primary-container);
  }

  .advanced-search-input, .portal-outlet {
    background-color: ftx.get-theme-color($theme, surface-container-highest);
    color: ftx.get-theme-color($theme, on-surface);
  }

  .fx-search-area {
    background-color: ftx.get-theme-color($theme, surface-container-low);
  }

  .mat-mdc-menu-panel {
    background-color: ftx.get-theme-color($theme, surface-container-high);
  }

  .mat-mdc-menu-item {
    min-height: 32px;
  }

  .mdc-button.mat-primary:not(.mat-mdc-button-disabled) {
    background-color: ftx.get-theme-color($theme, tertiary);
    color: ftx.get-theme-color($theme, on-tertiary);
  }

  .mdc-button.mat-accent:not(.mat-mdc-button-disabled) {
    background-color: ftx.get-theme-color($theme, secondary);
    color: ftx.get-theme-color($theme, on-secondary);
  }

  .mdc-button.mat-warn:not(.mat-mdc-button-disabled) {
    background-color: ftx.get-theme-color($theme, error-container);
    color: ftx.get-theme-color($theme, on-error-container);
  }

  .mat-mdc-card {
    background-color: ftx.get-theme-color($theme, surface-container-lowest) !important;
  }


  mat-row, mat-footer-row {
    min-height: 36px;
  }

  mat-header-row {
    min-height: 46px;
  }

  mat-row:not(.detail-row):hover {
    background-color: ftx.get-theme-color($theme, surface-container-lowest);
  }


}


:root {

  @include ftx.core-theme(ftx.$light-theme);
  @include ftx.all-component-themes(ftx.$light-theme);
  @include custom-style(ftx.$light-theme);
  @include ftx.button-density(-1);
  --default-text-size: 13px;

  --mat-standard-button-toggle-divider-color: #c9c9c9;
  --mat-toolbar-title-text-size: calc(var(--default-text-size) + 1px);
  --mat-form-field-container-text-size: var(--default-text-size);
  --mdc-outlined-text-field-label-text-size: var(--default-text-size);
  --mat-select-trigger-text-size: var(--default-text-size);
  --mat-switch-with-icon-handle-size: 20px;
  --mdc-switch-track-height: 24px;
  --mdc-switch-track-width: 46px;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0 2px;

  --mdc-dialog-container-shape: 12px;
  --mat-dialog-container-max-width: 80vw;
  --mat-option-label-text-size: var(--default-text-size);
  --mat-expansion-header-text-size: 14px;

  --mdc-text-button-label-text-weight: 400;

  --mat-table-row-item-label-text-size: var(--default-text-size);
  --mat-table-header-headline-size: var(--default-text-size);
  --mat-table-header-headline-line-height: var(--default-text-size);
  --mat-table-header-container-height: 46px;
  --mat-table-footer-supporting-text-weight: 500;

  --mat-menu-item-label-text-weight: 400;
  --mat-menu-item-label-text-size: var(--default-text-size);
  --mat-tree-node-text-size: var(--default-text-size);

  --mat-list-list-item-leading-icon-start-space: 12px;
  --mat-list-list-item-leading-icon-end-space: 12px;

  --mdc-list-list-item-label-text-size: calc(var(--default-text-size) - 1px);

  --mat-menu-divider-bottom-spacing: 0px;
  --mat-menu-divider-top-spacing: 0px;

  --mat-sidenav-container-shape: 0;
  --mdc-outlined-card-outline-width: 0;
}


.dark-theme {
  @include ftx.all-component-colors(ftx.$dark-theme);
  @include custom-style(ftx.$dark-theme);

  --mat-standard-button-toggle-divider-color: #5e5e5e !important;
}
